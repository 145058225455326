<template>
  <div>
    <heade currentrStr=""></heade>
    <div class="sai common-width">
      <a-breadcrumb>
        <a-breadcrumb-item
          ><a href="javascript:;" @click="to('./index')"
            >上一页</a
          ></a-breadcrumb-item
        >
        <a-breadcrumb-item>2022编译系统设计赛（华为毕昇杯）</a-breadcrumb-item>
      </a-breadcrumb>
      <div v-for="(item, index) in listAry" :key="index">
        <p class="look-del">{{ item.title }}</p>
        <p class="pad-left">1. 时间：{{ item.time }}</p>
        <p class="pad-left">
          2. 培训日程与直播地址：
          <a target="_blank" :href="item.href1"> 点击查看</a>
        </p>
        <p class="pad-left">
          3. 直播回放：
          <a target="_blank" :href="item.href2">点击查看回放</a>
        </p>
        <a-table
          bordered
          :columns="columns"
          :data-source="item.dataColumns"
          style="margin-top: 10px"
          :pagination="false"
          v-if="item.dataColumns.length != 0"
        >
          <a
            slot="action"
            slot-scope="text, record"
            target="_blank"
            :href="record.href3"
            v-if="record.href3 != ''"
            >{{ record.hrefName }}</a
          >

        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
const columns = [
  { title: "题目", dataIndex: "name", key: "name" },
  { title: "讲者", dataIndex: "age", key: "age" },
  {
    title: "操作",
    dataIndex: "address",
    key: "1",
    scopedSlots: { customRender: "action" },
  },
];

import heade from "@/components/header.vue";
export default {
  components: {
    heade,
  },
  data() {
    return {
      columns,
      listAry: [],
    };
  },
  mounted() {
    this.listAry = [
      {
        title: "2022编译系统设计赛（华为毕昇杯）初赛综合答疑会",
        time: "2022年7月30日 14:00-18:00",
        href1: "https://mp.weixin.qq.com/s/CjbeT8ewZVeZPmlqi3PBoQ",
        href2:
          "https://www.bilibili.com/video/BV1ge4y1X7gv?spm_id_from=333.337.search-card.all.click",
        dataColumns: [],
      },
      {
        title: "2022编译系统设计赛（华为毕昇杯）第七场技术培训",
        time: "2022年7月16日 14:00-17:00",
        href1: "https://mp.weixin.qq.com/s/YPDmdBld0q_1oM6mooDN4Q",
        href2:
          "https://www.bilibili.com/video/BV1wW4y1m7iy?spm_id_from=333.999.0.0&vd_source=57ee8b89964a4303d3e3fa4492aa397f",
        dataColumns: [
          {
            name: `主持人`,
            age: "翟季冬",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `SIMD编程和编译优化`,
            age: "	唐适之",
            href3: "https://pan.educg.net/#/s/1G8Ul",
            hrefName: "下载",
          },
          {
            name: `编译器循环优化技术介绍`,
            age: "姚贤庆",
            href3: "https://pan.educg.net/#/s/1G8Ul",
            hrefName: "下载",
          },
          {
            name: `多级IR设计和并行化多线程优化`,
            age: "黄奕桐",
            href3: "https://pan.educg.net/#/s/1G8Ul",
            hrefName: "下载",
          },
          {
            name: `编译器系统设计赛参赛心得与经验`,
            age: "陈金宝",
            href3: "https://pan.educg.net/#/s/1G8Ul",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022编译系统设计赛（华为毕昇杯）第六场技术培训",
        time: "2022年7月9日 14:00-17:00",
        href1: "https://mp.weixin.qq.com/s/jfiLmvp_dhWH_kxYUF-fng",
        href2:
          "https://www.bilibili.com/video/BV1bW4y1S76T?spm_id_from=333.999.0.0",
        dataColumns: [
          {
            name: `ARM体系架构及其优化技术简介`,
            age: "计卫星",
            href3: "https://pan.educg.net/#/s/M9vsB",
            hrefName: "下载",
          },
          {
            name: `基于ARM64架构的编译器优化实践`,
            age: "王涛",
            href3: "https://pan.educg.net/#/s/5GRIe",
            hrefName: "下载",
          },
          {
            name: `LLVM编译器针对RISC-V架构的CodesSize优化`,
            age: "王锋",
            href3: "https://pan.educg.net/#/s/gY0FL",
            hrefName: "下载",
          },
          {
            name: `编译器系统设计赛参赛心得与经验`,
            age: "徐遥",
            href3: "https://pan.educg.net/#/s/GavIZ",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022编译系统设计赛（华为毕昇杯）第五场技术培训",
        time: "2022年7月2日 14:00-17:00",
        href1: "https://mp.weixin.qq.com/s/rMGN06msYiID5Tt8nb1hUQ",
        href2:
          "https://www.bilibili.com/video/BV19T411u7yv?spm_id_from=333.999.0.0",
        dataColumns: [
          {
            name: `主持人`,
            age: "冯晓兵",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `向量化简介`,
            age: "方建滨",
            href3: "https://pan.educg.net/#/s/zKmFO",
            hrefName: "下载",
          },
          {
            name: `异构平台上的融合编译技术`,
            age: "崔慧敏",
            href3: "https://pan.educg.net/#/s/Z1mHR",
            hrefName: "下载",
          },
          {
            name: `静态分支预测与基本块重排介绍`,
            age: "赵川峰",
            href3: "https://pan.educg.net/#/s/Wj3F5",
            hrefName: "下载",
          },
          {
            name: `浅谈IR优化`,
            age: "何纪宏",
            href3: "https://pan.educg.net/#/s/aywU3",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022编译系统设计赛（华为毕昇杯）第四场技术培训",
        time: "2022年6月25日 14:00-17:00",
        href1: "https://mp.weixin.qq.com/s/4TpTp_fglx3v_xY_FOgYzQ",
        href2:
          "https://www.bilibili.com/video/BV1sB4y1q7jP?spm_id_from=333.999.0.0",
        dataColumns: [
          {
            name: `主持人`,
            age: "刘先华",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `TVM编译框架中间表示简介`,
            age: "陈和",
            href3: "https://pan.educg.net/#/s/Q7XhX",
            hrefName: "下载",
          },
          {
            name: `LLVM IR调试方法介绍`,
            age: "陈民栋",
            href3: "https://pan.educg.net/#/s/2gxH1",
            hrefName: "下载",
          },
          {
            name: `安全C语言-缓冲区溢出问题分析和优化`,
            age: "徐茂达",
            href3: "https://pan.educg.net/#/s/eyWIa",
            hrefName: "下载",
          },
          {
            name: `编译器构建的学习与实践`,
            age: "毕彰谦",
            href3: "https://pan.educg.net/#/s/Xjafm",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022编译系统设计赛（华为毕昇杯）第三场技术培训",
        time: "2022年6月18日 09:00-12:00",
        href1: "https://mp.weixin.qq.com/s/mWPeS_4371a3EjDPbHPISg",
        href2:
          "https://www.bilibili.com/video/BV1wF411c75B?spm_id_from=333.999.0.0&vd_source=57ee8b89964a4303d3e3fa4492aa397f",
        dataColumns: [
          {
            name: `SysY2022语言及文法解读`,
            age: "张昱",
            href3: "https://pan.educg.net/#/s/4gVFr",
            hrefName: "下载",
          },
          {
            name: `基于ANTLR的编译器前端开发`,
            age: "刘硕",
            href3: "https://pan.educg.net/#/s/kyGI5",
            hrefName: "下载",
          },
          {
            name: `浅谈编译器的语法分析`,
            age: "刘桢炜",
            href3: "https://pan.educg.net/#/s/lyWc9",
            hrefName: "下载",
          },
          {
            name: `毕昇C++在编程模型设计上的实践`,
            age: "杨杨",
            href3: "https://pan.educg.net/#/s/9yehm",
            hrefName: "下载",
          },
          {
            name: `AI编程语言与编译器漫游指南`,
            age: "赵家程",
            href3: "https://pan.educg.net/#/s/0w7hK",
            hrefName: "下载",
          },
          {
            name: `浅析编程语言的类型系统`,
            age: "冯新宇",
            href3: "https://pan.educg.net/#/s/oyvS1",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022编译系统设计赛（华为毕昇杯）第二场技术培训",
        time: "2022年6月11日 14:00-18:00",
        href1: "https://mp.weixin.qq.com/s/hsC71OftKQYKk3Yhp1oMXA",
        href2:
          "https://www.bilibili.com/video/BV18g41197h5?spm_id_from=333.999.0.0&vd_source=57ee8b89964a4303d3e3fa4492aa397f",
        dataColumns: [
          {
            name: `主持人`,
            age: "黄林鹏",
            href3: "",
            hrefName: "下载",
          },
          {
            name: `LLVM 编译框架简介`,
            age: "于琳雅",
            href3: "https://pan.educg.net/#/s/yWyhO",
            hrefName: "下载",
          },
          {
            name: `GCC初步`,
            age: "黄春",
            href3: "https://pan.educg.net/#/s/O3xS7",
            hrefName: "下载",
          },
          {
            name: `浅谈编译器通用测试方法`,
            age: "朱庆平",
            href3: "https://pan.educg.net/#/s/Yavcr",
            hrefName: "下载",
          },
          {
            name: `往年参赛经验与技术分享`,
            age: "于剑",
            href3: "https://pan.educg.net/#/s/qAmI2",
            hrefName: "下载",
          },
          {
            name: `我的编译之路：从台前到幕后`,
            age: "邢其正",
            href3: "https://pan.educg.net/#/s/daOHv",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022编译系统设计赛（华为毕昇杯）第一场技术培训",
        time: "2022年6月04日 14:00-18:00",
        href1: "https://mp.weixin.qq.com/s/INZcc-HWz2CpY5QblcLI2Q",
        href2:
          "https://www.bilibili.com/video/BV17g411d7wj?spm_id_from=333.999.0.0",
        dataColumns: [
          {
            name: `介绍编译课程内容框架及整个培训的安排`,
            age: "张莉",
            href3: "https://pan.educg.net/#/s/mBQi1",
            hrefName: "下载",
          },
          {
            name: `编译原理概述`,
            age: "杨海燕",
            href3: "https://pan.educg.net/#/s/12zIl",
            hrefName: "下载",
          },
          {
            name: `中间代码和目标代码的优化：基础篇`,
            age: "许畅",
            href3: "https://pan.educg.net/#/s/QkwcX",
            hrefName: "下载",
          },
          {
            name: `NKU-TEDA编译大赛参赛心得分享`,
            age: "杨科迪",
            href3: "https://pan.educg.net/#/s/PAmcq",
            hrefName: "下载",
          },
          {
            name: `从零开始的编译器学习`,
            age: "吴家焱",
            href3: "https://pan.educg.net/#/s/GkdiZ",
            hrefName: "下载",
          },
        ],
      },
      {
        title: "2022编译系统设计赛（华为毕昇杯）技术培训会",
        time: "2022年5月07日 14:00-16:00",
        href1: "https://mp.weixin.qq.com/s/T6X8-YwT9mnDtBixjW8vvw",
        href2:
          "https://www.bilibili.com/video/BV11R4y1A7Hp?spm_id_from=333.999.0.0",
        dataColumns: [
          {
            name: `编译技术前沿`,
            age: "魏伟",
            href3: "",
            hrefName: "资料整理中",
          },
          {
            name: `2022年编译大赛介绍`,
            age: "刘先华",
            href3: "https://pan.educg.net/#/s/LYAsM",
            hrefName: "下载",
          },
          {
            name: `编译课程教学与竞赛`,
            age: "班晓娟",
            href3: "https://pan.educg.net/#/s/r0lfJ",
            hrefName: "下载",
          },
          {
            name: `编译竞赛参赛心得`,
            age: "夏海曦",
            href3: "https://pan.educg.net/#/s/OpxT7",
            hrefName: "下载",
          },
        ],
      },
    ];
  },
  methods: {
    to(url) {
      this.$router.push({ path: url });
    },
  },
};
</script>
<style scoped>
div,
p {
  padding: 0;
  margin: 0;
}
.look-del {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  padding-top: 20px;
}
.pad-left {
  padding: 5px 0;
  padding-left: 13px;
  text-indent: -13px;
}
.sai {
  margin-top: 20px !important;
  text-align: left;
}
/deep/ .ant-breadcrumb {
  text-align: left;
}
</style>